var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset__inner" }, [
    _c(
      "div",
      {
        staticClass: "back-btn",
        on: {
          click: function($event) {
            return _vm.$emit("hideReset")
          }
        }
      },
      [
        _c("i", { staticClass: "ri-arrow-left-line" }),
        _c("span", [_vm._v("Back to Login")])
      ]
    ),
    _vm.step === 1
      ? _c(
          "form",
          {
            staticClass: "reset__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.sendSms($event)
              }
            }
          },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.phone.required && _vm.$v.phone.minLength,
                  "field__label--invalid":
                    (_vm.$v.phone.$dirty && !_vm.$v.phone.required) ||
                    (_vm.$v.phone.$dirty && !_vm.$v.phone.minLength)
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.phone,
                      expression: "phone",
                      modifiers: { trim: true }
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "### ### ####",
                      expression: "'### ### ####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: {
                    type: "text",
                    name: "phone",
                    placeholder: "Phone number"
                  },
                  domProps: { value: _vm.phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.phone = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button button--fill reset__button",
                attrs: { type: "submit" }
              },
              [_vm._v(" Reset Password ")]
            )
          ]
        )
      : _vm._e(),
    _vm.step === 2
      ? _c(
          "form",
          {
            staticClass: "reset__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.sendConfirmCode($event)
              }
            }
          },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.code.required && _vm.$v.code.minLength,
                  "field__label--invalid":
                    (_vm.$v.code.$dirty && !_vm.$v.code.required) ||
                    (_vm.$v.code.$dirty && !_vm.$v.code.minLength)
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.code,
                      expression: "code",
                      modifiers: { trim: true }
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "###-###",
                      expression: "'###-###'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: {
                    type: "text",
                    name: "code",
                    placeholder: "SMS Code"
                  },
                  domProps: { value: _vm.code },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.code = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button button--fill reset__button",
                attrs: { type: "submit" }
              },
              [_vm._v(" Confirm ")]
            )
          ]
        )
      : _vm._e(),
    _vm.step === 3
      ? _c(
          "form",
          {
            staticClass: "reset__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.resetPassword($event)
              }
            }
          },
          [
            _c(
              "label",
              {
                staticClass: "field__label login__label",
                class: {
                  "field__label--filled":
                    _vm.$v.password.required && _vm.$v.password.minLength,
                  "field__label--invalid":
                    (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
                    (_vm.$v.password.$dirty && !_vm.$v.password.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title field__title" }, [
                  _vm._v("Enter new password (min 8 charachters)")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.password,
                      expression: "password",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "field__input",
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: "Password"
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label login__label",
                class: {
                  "field__label--filled":
                    _vm.$v.passwordConfirmation.required &&
                    _vm.$v.passwordConfirmation.minLength,
                  "field__label--invalid":
                    (_vm.$v.passwordConfirmation.$dirty &&
                      !_vm.$v.passwordConfirmation.required) ||
                    (_vm.$v.passwordConfirmation.$dirty &&
                      !_vm.$v.passwordConfirmation.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title field__title" }, [
                  _vm._v("Password Confirmation")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.passwordConfirmation,
                      expression: "passwordConfirmation",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "field__input",
                  attrs: {
                    type: "password",
                    name: "passwordConfirmation",
                    placeholder: "Password Confirmation"
                  },
                  domProps: { value: _vm.passwordConfirmation },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.passwordConfirmation = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button button--fill reset__button",
                attrs: { type: "submit" }
              },
              [_vm._v(" Save Password ")]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }