<template>
  <div class="login__box">
    <div class="login__wrapper">
      <ResetPassword v-if="showReset" @hideReset="hideReset" />
      <div v-else class="login__inner">
        <div class="login__header">Log In to STSApp</div>
        <form class="login__form" @submit.prevent="login">
          <label
            class="field__label login__label"
            :class="{
              'field__label--filled':
                ($v.email.required && $v.email.email) || ($v.email.required && $v.email.minLength),
              'field__label--invalid':
                ($v.email.$dirty && !$v.email.required) ||
                ($v.email.$dirty && !$v.email.minLength) ||
                ($v.email.$dirty && !$v.email.email) ||
                loginError,
              'animate__shakeX animate__animated': loginError
            }"
          >
            <div class="field__title field__title">Login</div>
            <input
              v-model.trim="email"
              class="field__input"
              type="text"
              name="email"
              placeholder="Phone number"
              autocomplete="username"
            />
          </label>
          <div class="login__form-warning">
            <i class="ri-information-fill"></i>
            <span>No dashes or spaces for cellphone numbers</span>
          </div>
          <label
            class="field__label login__label"
            :class="{
              'field__label--filled': $v.password.required && $v.password.minLength,
              'field__label--invalid':
                ($v.password.$dirty && !$v.password.required) ||
                ($v.password.$dirty && !$v.password.minLength) ||
                loginError,
              'animate__shakeX animate__animated': loginError
            }"
          >
            <div class="field__title field__title">Password</div>
            <input
              v-model.trim="password"
              class="field__input"
              type="password"
              name="password"
              placeholder="Password"
              autocomplete="current-password"
            />
          </label>
          <button type="submit" class="button button--fill login__button">
            Log In
          </button>
          <div class="forgot" @click="showReset = true">Forgot Password?</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators'
import Onboarding from '@/views/Client/Onboarding'
import ResetPassword from './resetPassword'

export default {
  components: {ResetPassword},
  data() {
    return {
      email: '',
      password: '',
      loginError: false,
      showReset: false
    }
  },
  methods: {
    async login() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 660}
      let modalWidth = mobile ? {width: '99.5%'} : {width: 480}

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const email = this.email
      const password = this.password
      try {
        await this.$store.dispatch('login', {login: email, password})
        const user = this.$store.getters.user
        if (user.role === 'super_admin') {
          this.$router.push('/companies')
        } else if (user.role === 'company_admin') {
          this.$router.push('/users/drivers')
        } else if (user.role === 'driver') {
          this.$router.push('/deliveries')
        } else if (user.role === 'customer' && user?.customer?.isEnterprise) {
          this.$router.push('/enterprise')
        } else if (user.role === 'customer') {
          this.$router.push('/public/orders')
          if (user.showTips) {
            this.$modal.display(
              Onboarding,
              {},
              {
                name: 'OnboardingModal',
                transition: 'none',
                overlayTransition: 'none',
                ...modalHeight,
                ...modalWidth,
                classes: 'modal modal-onboarding'
              }
            )
          }
        }
      } catch (e) {
        if (e.response.status == 422) {
          this.loginError = true
        }
        setTimeout(() => {
          this.loginError = false
        }, 2000)
      }
    },
    hideReset() {
      this.showReset = false
    }
  },
  validations: {
    email: {required, minLength: minLength(10)},
    password: {required, minLength: minLength(8)}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
