var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login__box" }, [
    _c(
      "div",
      { staticClass: "login__wrapper" },
      [
        _vm.showReset
          ? _c("ResetPassword", { on: { hideReset: _vm.hideReset } })
          : _c("div", { staticClass: "login__inner" }, [
              _c("div", { staticClass: "login__header" }, [
                _vm._v("Log In to STSApp")
              ]),
              _c(
                "form",
                {
                  staticClass: "login__form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    }
                  }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "field__label login__label",
                      class: {
                        "field__label--filled":
                          (_vm.$v.email.required && _vm.$v.email.email) ||
                          (_vm.$v.email.required && _vm.$v.email.minLength),
                        "field__label--invalid":
                          (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                          (_vm.$v.email.$dirty && !_vm.$v.email.minLength) ||
                          (_vm.$v.email.$dirty && !_vm.$v.email.email) ||
                          _vm.loginError,
                        "animate__shakeX animate__animated": _vm.loginError
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title field__title" }, [
                        _vm._v("Login")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.email,
                            expression: "email",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "field__input",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: "Phone number",
                          autocomplete: "username"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value.trim()
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "login__form-warning" }, [
                    _c("i", { staticClass: "ri-information-fill" }),
                    _c("span", [
                      _vm._v("No dashes or spaces for cellphone numbers")
                    ])
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "field__label login__label",
                      class: {
                        "field__label--filled":
                          _vm.$v.password.required && _vm.$v.password.minLength,
                        "field__label--invalid":
                          (_vm.$v.password.$dirty &&
                            !_vm.$v.password.required) ||
                          (_vm.$v.password.$dirty &&
                            !_vm.$v.password.minLength) ||
                          _vm.loginError,
                        "animate__shakeX animate__animated": _vm.loginError
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title field__title" }, [
                        _vm._v("Password")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.password,
                            expression: "password",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "field__input",
                        attrs: {
                          type: "password",
                          name: "password",
                          placeholder: "Password",
                          autocomplete: "current-password"
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value.trim()
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button button--fill login__button",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(" Log In ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "forgot",
                      on: {
                        click: function($event) {
                          _vm.showReset = true
                        }
                      }
                    },
                    [_vm._v("Forgot Password?")]
                  )
                ]
              )
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }