<template>
  <div class="reset__inner">
    <div class="back-btn" @click="$emit('hideReset')">
      <i class="ri-arrow-left-line"></i>
      <span>Back to Login</span>
    </div>
    <form v-if="step === 1" class="reset__form" @submit.prevent="sendSms">
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.phone.required && $v.phone.minLength,
          'field__label--invalid': ($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength)
        }"
      >
        <input
          v-model.trim="phone"
          v-mask="'### ### ####'"
          class="field__input"
          type="text"
          name="phone"
          placeholder="Phone number"
        />
      </label>
      <button type="submit" class="button button--fill reset__button">
        Reset Password
      </button>
    </form>
    <form v-if="step === 2" class="reset__form" @submit.prevent="sendConfirmCode">
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.code.required && $v.code.minLength,
          'field__label--invalid': ($v.code.$dirty && !$v.code.required) || ($v.code.$dirty && !$v.code.minLength)
        }"
      >
        <input
          v-model.trim="code"
          v-mask="'###-###'"
          class="field__input"
          type="text"
          name="code"
          placeholder="SMS Code"
        />
      </label>
      <button type="submit" class="button button--fill reset__button">
        Confirm
      </button>
    </form>
    <form v-if="step === 3" class="reset__form" @submit.prevent="resetPassword">
      <label
        class="field__label login__label"
        :class="{
          'field__label--filled': $v.password.required && $v.password.minLength,
          'field__label--invalid':
            ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)
        }"
      >
        <div class="field__title field__title">Enter new password (min 8 charachters)</div>
        <input v-model.trim="password" class="field__input" type="password" name="password" placeholder="Password" />
      </label>
      <label
        class="field__label login__label"
        :class="{
          'field__label--filled': $v.passwordConfirmation.required && $v.passwordConfirmation.minLength,
          'field__label--invalid':
            ($v.passwordConfirmation.$dirty && !$v.passwordConfirmation.required) ||
            ($v.passwordConfirmation.$dirty && !$v.passwordConfirmation.minLength)
        }"
      >
        <div class="field__title field__title">Password Confirmation</div>
        <input
          v-model.trim="passwordConfirmation"
          class="field__input"
          type="password"
          name="passwordConfirmation"
          placeholder="Password Confirmation"
        />
      </label>
      <button type="submit" class="button button--fill reset__button">
        Save Password
      </button>
    </form>
  </div>
</template>

<script>
import {required, minLength, sameAs} from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      phone: '',
      step: 1,
      code: '',
      password: '',
      passwordConfirmation: '',
      isLoading: false
    }
  },
  methods: {
    async sendSms() {
      if (this.isLoading) return
      const phone = this.phone.split(' ').join('')
      this.isLoading = true
      try {
        const req = await this.$store.dispatch('resetPassword/resetPasswordRequest', phone)
        if (req) this.step = 2
      } finally {
        this.isLoading = false
      }
    },
    async sendConfirmCode() {
      if (this.isLoading) return
      this.isLoading = true
      try {
        const req = await this.$store.dispatch('resetPassword/verifyResetPasswordRequest', this.code)
        if (req) this.step = 3
      } finally {
        this.isLoading = false
      }
    },
    async resetPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      if (this.isLoading) return
      const password = {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation
      }
      this.isLoading = true
      try {
        const req = await this.$store.dispatch('resetPassword/resetPassword', password)
        if (req) this.$emit('hideReset')
      } finally {
        this.isLoading = false
      }
    }
  },
  validations: {
    phone: {minLength: minLength(10)}, // TODO: Add Cnanda phone validations,
    code: {minLength: minLength(7)},
    password: {required, minLength: minLength(8)},
    passwordConfirmation: {required, sameAsPassword: sameAs('password'), minLength: minLength(8)}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
